import { lazy, Suspense } from "react"
import { Navigate, useRoutes } from "react-router-dom"
import { useSelector } from "react-redux"

const Home = lazy(() => import("./Pages/Home"))
const Perfil = lazy(() => import("./Pages/Perfiles"))
const Login = lazy(() => import("./Pages/Login"))
const Registro = lazy(() => import("./Pages/Registro"))
const RegistroStartups = lazy(() => import("./Pages/Registro/Startups"))
const CheckEmail = lazy(() => import("./Pages/CheckEmail"))
const ResetPassword = lazy(() => import("./Pages/ResetPassword"))
const DashboardLayout = lazy(() => import("./Layouts/Dashboard"))
const Startups = lazy(() => import("./Pages/Startups"))
const Oportunidades = lazy(() => import("./Pages/Oportunidades"))
const OportunidadesPatrocinador = lazy(() => import("./Pages/OportunidadesPatrocinadores"))

const Mercado = lazy(() => import("./Pages/Mercado"))
const Documentos = lazy(() => import("./Pages/Documentos"))
const DocumentsAdmin = lazy(() => import("./Pages/DocumentsAdmin"))
const DemoDay = lazy(() => import("./Pages/DemoDay"))
const Novedades = lazy(() => import("./Pages/Novedades"))
const NotFound = lazy(() => import("./Pages/NotFound"))
const Users = lazy(() => import("./Pages/Users"))
const Chat = lazy(() => import("./Pages/Chat"))
const ViewProfileSponsor = lazy(() => import("./Pages/ViewProfile/Sponsor"))
const ViewProfileStartup = lazy(() => import("./Pages/ViewProfile/Startup"))
const ViewInvestorSponsor = lazy(() => import("./Pages/ViewProfile/Investor"))
const InterestedList = lazy(() => import("./Pages/Startup/InterestedList"))
const InfoAccess = lazy(() => import("./Pages/Startup/InfoAccess"))
const Configuracion = lazy(() => import("./Pages/Configuracion"))
const Calendar = lazy(() => import("./Pages/Calendar"))
const DetalleRegistros = lazy(() => import("./Pages/Users/DetalleRegistros"))
const DetalleLogs = lazy(() => import("./Pages/Users/DetalleLogs"))
const Notifications = lazy(() => import("./Pages/Notificaciones"))
const StartupIntro = lazy(() => import("./Pages/Startup"))
const CuentaSuspendida = lazy(() => import("./Pages/CuentaSuspendida"))
const RecuperarPassword = lazy(() => import("./Pages/RecuperarPassword"))

const expiredToken = () => {
  const exp = localStorage.getItem("user-token-expired-at")
  const newDate = new Date(exp)
  if (newDate.getTime() < new Date().getTime()) return false
  return true
}

const Routes = () => {
  const userToken = useSelector((state) => state.auth.token)
  const userType = useSelector((state) => state.auth.userType)
  const userLogin = userToken !== null && expiredToken()

  const elements = useRoutes([
    {
      path: "/",
      index: true,
      element: userLogin ? <Navigate to={`/dashboard-${userType}`} /> : <Login />,
    },
    {
      path: "/dashboard-scalex",
      index: true,
      element: userLogin ? <Navigate to={"/dashboard-scalex/usuarios"} /> : <Login />,
    },
    {
      path: `/dashboard-scalex/inicio`,
      index: true,
      element: userLogin ? <Navigate to={"/dashboard-scalex/usuarios"} /> : <Login />,
    },
    {
      path: `/dashboard-scalex/inicio`,
      index: true,
      element: userLogin ? <Navigate to={"/dashboard-scalex/usuarios"} /> : <Login />,
    },
    {
      path: `/dashboard-${userType}`,
      index: true,
      element: userLogin ? <Navigate to={`/dashboard-${userType}/inicio`} /> : <Login />,
    },
    { path: "registro", element: <Registro /> },
    { path: "registro/startups", element: <RegistroStartups /> },
    { path: "check-email", element: <CheckEmail /> },
    { path: "resetpassword", element: <ResetPassword /> },
    { path: "cuenta-suspendida", element: <CuentaSuspendida /> },
    { path: "restablecer-password/:token", element: <ResetPassword /> },
    { path: "crear-password/:token", element: <ResetPassword /> },
    { path: "recuperar-password", element: <RecuperarPassword /> },
    { path: "404", element: <NotFound /> },
    {
      path: `/dashboard-${userType}`,
      element: userLogin ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: "inicio", element: <Home /> },
        { path: "documentos", element: <Documentos /> },
        { path: "novedades", element: <Novedades /> },
        { path: "notificaciones", element: <Notifications /> },
        { path: "perfil", element: <Perfil /> },
        { path: "chat", element: <Chat /> },
        { path: "chat/:id", element: <Chat /> },
        { path: "patrocinador/:id/perfil", element: <ViewProfileSponsor /> },
        { path: "inversionista/:id/perfil", element: <ViewInvestorSponsor /> },
        { path: "startups/:id/perfil", element: <ViewProfileStartup /> },
        { path: "startups/:id/inversionistas_interesados", element: <InterestedList /> },
        { path: "startups/:id/solicitudes_informacion", element: <InfoAccess /> },
        { path: "configuracion", element: <Configuracion /> },
        { path: "calendario", element: <Calendar /> },
      ],
    },
    {
      path: "/dashboard-sponsor",
      element: <DashboardLayout />,
      children: [
        /* { path: "startups", element: <Startups /> }, */
        { path: "oportunidades", element: <Oportunidades /> },
        { path: "oportunidades-patrocinador", element: <OportunidadesPatrocinador /> },
        { path: "mercado", element: <Mercado /> },
      ],
    },
    {
      path: "/dashboard-startup",
      element: <DashboardLayout />,
      children: [
        { path: "oportunidades", element: <Oportunidades /> },
        { path: "oportunidades-patrocinador", element: <OportunidadesPatrocinador /> },
      ],
    },
    {
      path: "/dashboard-investor",
      element: <DashboardLayout />,
      children: [
        { path: "oportunidades", element: <Oportunidades /> },
        { path: "oportunidades-patrocinador", element: <OportunidadesPatrocinador /> },
        { path: "startups", element: <Startups /> },
        { path: "mercado", element: <Mercado /> },
      ],
    },
    {
      path: `/dashboard-scalex`,
      element: userLogin ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: "demoday", element: <DemoDay /> },
        { path: "admin-documentos", element: <DocumentsAdmin /> },
        { path: "mercado", element: <Mercado /> },
        { path: "oportunidades", element: <Oportunidades /> },
        { path: "oportunidades-patrocinador", element: <OportunidadesPatrocinador /> },
        { path: "usuarios", element: <Users /> },
        { path: "usuarios/:id/formulario_registro", element: <DetalleRegistros /> },
        { path: "usuarios/:id/log", element: <DetalleLogs /> },
        { path: "calendario", element: <Calendar /> },
      ],
    },
    { path: "/startup", element: <StartupIntro /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ])
  return elements
}

const AppRoute = () => {
  return (
    <Suspense>
      <Routes />
    </Suspense>
  )
}

export default AppRoute
